<template>
<maintenance :mainteneur=1 />

</template>

<script>

import Maintenance from '../../apps/maintenance/Maintenance.vue';

export default {
  name: 'app',
  components:{Maintenance}
}
</script>
