<template>
    <div ref="ouvragelistmodal">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-composant
            variant="outline-primary"
            v-if="!multi"
        >
            Sélectionner un composant
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-composant
            variant="outline-primary"
            v-if="multi"
        >
            Sélectionner un ou plusieurs composants
        </b-button>        
        <b-modal
        id="modal-composant"
        modal-class="ouvrage-modal nopad"
        cancel-variant="outline-secondary"
        ok-title="Sélectionner"
        @ok=sendSelected
        cancel-title="Fermer"
        centered
        title="Liste des composants"
        >

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="composants"
            responsive
            :fields="fieldsComposant"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            v-on:toggle-box="onToggleBoxComp"
            >

            <template #cell(releve)="data">
                <div class="text-nowrap">
                <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                </div>
            </template>
            <!-- Column: Role -->
            <template #cell(structure)="data">
                <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ data.item.structure }}</span>
                </div>
            </template>

            <!-- Column: Role -->
            <template #cell(name)="data">
                <div class="text-nowrap">
                <b-link @click="onAddComposant(data.item)">{{ data.item.name }}</b-link>
                </div>
            </template>


            </b-table>

        </b-modal>
    </div>
</template>

<script>

import {
BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BLink, BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ComposantsIn from '@/@fake-db/data/apps/composant.js'

export default {
  directives: {
    Ripple,
  },    
  name: "composantlistmodal",
  components: {BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BTable,BLink},
  methods: {
    onAddComposant: function (arrayOuvrage) {
        this.$emit('select-composant', arrayOuvrage)
        this.hideModal('modal-composant')
    },
    onToggleBoxComp: function (inouvrage) {

        for (const [index, [, value]] of Object.entries(Object.entries(this.composants))) {
            if (value.name == inouvrage.name) {
                this.composants[index].ischecked = !this.composants[index].ischecked
            }
        }
    },    
    sendSelected: function () {
        for (const [index, [, value]] of Object.entries(Object.entries(this.composants))) {
            if (this.composants[index].ischecked) {
                this.$emit('select-composant', this.composants[index])
                this.composants[index].ischecked = false
            }
        }
        this.hideModal('modal-composant')
    },    
    hideModal (id) {
        this.$root.$emit('bv::hide::modal',id)
    },
    addComposant: function (composant) {
        
        this.composants.push({structure:composant.id, name:composant.name, iqoa:composant.iqoa, type:composant.type.text, image:composant.image});

    },    
  },
  mounted() {
    if (this.multi) {
        this.fieldsComposant = [
                { key: "chbx", label: "", type: "checkbox", thStyle: { width: "8%" } },
                { key: "structure", label: "Structure", type: "text", thStyle: { width: "40%" }},
                { key: "name", label: "Type de composant", type: "text" },
            ]
    } 
  },  
 
  created() {
       //forger la recup des composants
       this.ComposantsIn.itemsComposant.forEach(composant => this.composants.push({structure:"Equipements sur ouvrage",name:composant.type}))
       this.ComposantsIn.itemsComposant1.forEach(composant => this.composants.push({structure:"Equipements sous ouvrage",name:composant.type}))
       this.ComposantsIn.itemsComposant2.forEach(composant => this.composants.push({structure:"Elements de protection",name:composant.type}))
       this.ComposantsIn.itemsComposant3.forEach(composant => this.composants.push({structure:"Structure",name:composant.type}))
  },

  props: {
    multi: {        
        type: Boolean,
            default: () => true
        },    
   },
   data() { 



        return {
            fieldsComposant : [
                { key: "structure", label: "Structure", type: "text", thStyle: { width: "50%",height:"30px" }},
                { key: "name", label: "Type de composant", type: "text" },
            ],
            composants: [],              
       }
   },
  setup() {
    return {
      ComposantsIn
    }
  },

  };
</script>

<style>
.ouvrage-modal .modal-dialog{
    max-width: 800px;
}
.nopad th, .nopad td {
    padding: 0px;
}

.ouvrage-modal .modal-body          { overflow: auto; height: 600px;padding:0; }
.ouvrage-modal .modal-body  thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
.ouvrage-modal table  { border-collapse: collapse; width: 100%; }
.ouvrage-modal th     { background:#eee; }
.ouvrage-modal .custom-checkbox{
    margin-left: 15px;
}
</style>


