<template>
    <div ref="ouvragelistmodal">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-desordre
            variant="outline-primary"
            v-if="!multi"
        >
            Sélectionner un désordre
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-desordre
            variant="outline-primary"
            v-if="multi"
        >
            Sélectionner un ou plusieurs désordres
        </b-button>        
        <b-modal
        id="modal-desordre"
        modal-class="ouvrage-modal nopad"
        cancel-variant="outline-secondary"
        ok-title="Sélectionner"
        @ok=sendSelected
        cancel-title="Fermer"
        centered
        title="Liste des désordres"
        >

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="desordres"
            responsive
            :fields="fieldsDesordre"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            v-on:toggle-box="onToggleBoxDes"
            >

            <template #cell(releve)="data">
                <div class="text-nowrap">
                <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                </div>
            </template>
            <!-- Column: Role -->
            <template #cell(structure)="data">
                <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{ data.item.structure }}</span>
                </div>
            </template>

            <!-- Column: Role -->
            <template #cell(libelle)="data">
                <div class="text-nowrap">
                <b-link @click="onAddDesordre(data.item)">{{ data.item.libelle }}</b-link>
                </div>
            </template>


            </b-table>

        </b-modal>
    </div>
</template>

<script>

import {
BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BLink, BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import desordreStoreModule from '../views/apps/desordre/desordreStoreModule'

export default {
  directives: {
    Ripple,
  },    
  name: "desordrelistmodal",
  components: {BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BTable,BLink},
  methods: {
    onAddDesordre: function (arrayOuvrage) {
        this.$emit('select-desordre', arrayOuvrage)
        this.hideModal('modal-desordre')
    },
    onToggleBoxDes: function (inouvrage) {

        for (const [index, [, value]] of Object.entries(Object.entries(this.desordres))) {
            if (value.name == inouvrage.name) {
                this.desordres[index].ischecked = !this.desordres[index].ischecked
            }
        }
    },    
    sendSelected: function () {
        for (const [index, [, value]] of Object.entries(Object.entries(this.desordres))) {
            if (this.desordres[index].ischecked) {
                this.$emit('select-composant', this.desordres[index])
                this.desordres[index].ischecked = false
            }
        }
        this.hideModal('modal-desordre')
    },    
    hideModal (id) {
        this.$root.$emit('bv::hide::modal',id)
    },
    addDesordre: function (desordre) {
        
        this.desordres.push({composants:desordre.composants, libelle:desordre.libelle, description:desordre.description});

    },    
  },
  mounted() {
    if (this.multi) {
        this.fieldsDesordre = [
                { key: "chbx", label: "", type: "checkbox", thStyle: { width: "8%" } },
                { key: "composants", label: "Composant", type: "text", thStyle: { width: "40%" }},
                { key: "libelle", label: "Libellé désordre", type: "text" },
                { key: "description", label: "Description", type: "text" },
            ]
    } 
  },   

  props: {
    multi: {        
        type: Boolean,
            default: () => true
        },    
   },
   data() { 

        store
        .dispatch('app-desordre/fetchDesordres', {
            q: '',
        })
        .then(response => {
            let desordres = response.data.desordres
            desordres.forEach(desordre => this.addDesordre(desordre));
            this.currentab = true
        })
        .catch(() => {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error fetching desordre list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })        
        })

        return {
            fieldsDesordre : [
                { key: "composants", label: "Composant", type: "text", thStyle: { width: "40%" }},
                { key: "libelle", label: "Libellé désordre", type: "text" },
                { key: "description", label: "Description", type: "text" },
            ],
            desordres: [],              
       }
   },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-desordre'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, desordreStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

  },  

  };
</script>

<style>
.ouvrage-modal .modal-dialog{
    max-width: 800px;
}
.nopad th, .nopad td {
    padding: 0px;
}

.ouvrage-modal .modal-body          { overflow: auto; height: 600px;padding:0; }
.ouvrage-modal .modal-body  thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
.ouvrage-modal table  { border-collapse: collapse; width: 100%; }
.ouvrage-modal th     { background:#eee; }
.ouvrage-modal .custom-checkbox{
    margin-left: 15px;
}
</style>


