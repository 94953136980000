<template>
  <b-sidebar
    id="end-maintenance-sidebar"
    :visible="isEndMaintenanceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-end-maintenance-sidebar-active', val);showEndMaintenance(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">

        <h5 class="mb-0" v-if="maintenanceData.id">
         Déclaration fin d'opération
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->


                    <b-form-group
                    label="Dénomination"
                    label-for="nom"
                    >
                    <span>{{ maintenanceData.nom }} </span>

                    </b-form-group>

                    <b-form-group
                    label="Ouvrage / Composant"
                    label-for="nom"
                    >
                    <span>{{ maintenanceData.ouvragel }} / {{ maintenanceData.composant }}</span>

                    </b-form-group>

                 <b-form-group
                    label="Type d'opération"
                    label-for="nom"
                    >
                    <span>{{ maintenanceData.typeaction }} </span>

                    </b-form-group>

<hr/>


               <validation-provider
                    #default="validationContext"
                    name="date"
                >
                    <b-form-group
                    label="Date réalisée"
                    label-for="date"
                    >
                    <b-form-datepicker
                        id="date"
                        v-model="maintenanceData.date"
                        :state="getValidationState(validationContext)"
                        locale="fr"
                        v-bind="labels['fr'] || {}"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider> 

               <validation-provider
                    #default="validationContext"
                    name="coutprev"
                >
                    <b-form-group
                    label="Coût réalisé"
                    label-for="coutreal"
                    >
                    <b-form-input
                        id="coutreal"
                        v-model="maintenanceData.coutreal"
                        :state="getValidationState(validationContext)"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>   

               <validation-provider
                    #default="validationContext"
                    name="duree"
                >
                    <b-form-group
                    label="Durée réalisée(en jours)"
                    label-for="duree"
                    >
                    <b-form-input
                        id="duree"
                        v-model="maintenanceData.duree"
                        :state="getValidationState(validationContext)"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>                 
                
<hr/>

          <validation-provider
            #default="validationContext"
            name="documents"
          >
            <b-form-group
              label="Documents"
              label-for="documents"
            >
              <b-form-file
              placeholder="Ajouter des documents"
              multiple
              />      
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

<hr/>


          <!-- Form Actions -->
          <div class="d-flex mt-2 mb-2" style="float:right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 mb-2"
              type="submit"
              @click="OnEditEnd"
            >
              Déclarer fin d'opération
            </b-button>            
          </div>        
  

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BImg, BFormFile, BBadge, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import ouvragelistmodal from '@/components/OuvrageListModal.vue'
import composantlistmodal from '@/components/ComposantListModal.vue'
import desordrelistmodal from '@/components/DesordreListModal.vue'
import maintenanceStoreModule from '@/views/apps/maintenance/maintenanceStoreModule'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BImg,
    BFormFile,
    BBadge,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    desordrelistmodal,
    composantlistmodal,
    ouvragelistmodal,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEndMaintenanceSidebarActive',
    prop: 'inMaintenanceId',
    event: 'update:is-end-maintenance-sidebar-active',
  },
  props: {
    isEndMaintenanceSidebarActive: {
      type: Boolean,
      required: true,
    },
    inMaintenanceId: {
      type: Number,
      default: -1,
    },   
  },

 methods: {
    loadMaintenance() {
      if ( this.inMaintenanceId != -1) {
          store
          .dispatch('app-maintenance/fetchMaintenance', {
            id: this.inMaintenanceId,
          })
          .then(response => {
              this.maintenanceData = response.data
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching maintenance',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })        
          })
      } else {
        this.maintenanceData = JSON.parse(JSON.stringify(this.blankMaintenanceData))
      }
    },

    showEndMaintenance: function (val) { 

      if (val) {
        this.loadMaintenance()
      } else {
        this.desmaintenanceDatardreData = JSON.parse(JSON.stringify(this.blankMaintenanceData))
      }
    },

 formatDate: function(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
},

    OnEdit: function() {
        if (this.maintenanceData.typeaction.text)
            this.maintenanceData.typeaction = this.maintenanceData.typeaction.text
        if (this.maintenanceData.priorite.label)    
            this.maintenanceData.priorite = this.maintenanceData.priorite.label
       store
      .dispatch('app-maintenance/editMaintenance',this.maintenanceData)
      .then(response => {

          this.maintenanceData = response.data
          this.$emit('addmaintenance')
      })
      //.catch((e) => {console.log(e)})
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error saving maintenance',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })
        
    } ,

    OnEditEnd: function() {

       store
      .dispatch('app-maintenance/editMaintenance',this.maintenanceData)
      .then(response => {

          this.maintenanceData = response.data
          this.$emit('addmaintenance')
      })
      //.catch((e) => {console.log(e)})
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error saving maintenance',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })
        
    } ,

 },  

  data() {

      store
      .dispatch('app-maintenance/fetchTypeactions')
      .then(response => {
        this.typeActions = response.data.typeactions        
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching type actions list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })

    //this.desordreData.value = this.inDesordreData
    this.loadMaintenance()
 
    return {
       typeActions:[],
      required,
      alphaNum,
      email,
      prioriteOptions:[
        {value:"Faible",label:"Faible"},
        {value:"Moyenne",label:"Moyenne"},
        {value:"Haute",label:"Haute"},
      ],   

      labels: {
          fr: {
            labelNoDateSelected: 'Pas de date sélectionnée',
            labelHelp: ''
          },  
      },    
    }
  },

  setup(props, { emit }) {

    const USER_APP_STORE_MODULE_NAME = 'app-maintenance'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, maintenanceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankMaintenanceData = {
             id: '',
            nom:'',
            description:'',
            pilote:'',
            delai:'',
            priorite:'',
            coutprev:'',
            coutreal:'',
            duree:'25',
            
            ouvragel:'',
            composant:'',
            typeaction:'',
            statut:'A planifier',
            auteur:'John Doe',
            date:'',
            startdate:'2022/03/12',
            date:'2022/03/12',     
            cadre: '',
            desordre:'',
            documents:''   
    }

    let maintenanceData = ref(JSON.parse(JSON.stringify(blankMaintenanceData)))

//du sens ça ??
    if (props.inMaintenanceId != -1) {
      maintenanceData.value = JSON.parse(JSON.stringify(blankMaintenanceData))
    }

   const resolveUserStatusVariant = status => {
    if (status === 'A planifier') return 'warning'
    if (status === 'Terminé') return 'success'
    if (status === 'Planifié') return 'secondary'
    return 'primary'
  }   
    const resetmaintenanceData = () => {
      maintenanceData.value = JSON.parse(JSON.stringify(blankMaintenanceData))
    }

    const onSubmit = () => {
 
      emit('update:is-end-maintenance-sidebar-active', false)
      emit('addmaintenance', maintenanceData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmaintenanceData)

    return {
      maintenanceData,
      onSubmit,
      blankMaintenanceData,
      refFormObserver,
      getValidationState,
      resetForm,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-desordre-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
