<template>
  <b-sidebar
    id="add-new-maintenance-sidebar"
    :visible="isAddNewMaintenanceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-maintenance-sidebar-active', val);showMaintenance(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0" v-if="!maintenanceData.id">
          Ajout d'une nouvelle maintenance
        </h5>

        <h5 class="mb-0" v-if="maintenanceData.id">
          Modification d'une maintenance
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
            <span style="float:right" class="mb-1">          
            <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(maintenanceData.statut)}`"
            class="text-capitalize"
          >
            {{ maintenanceData.statut }}
          </b-badge></span>
          <!-- Full Name -->

                <validation-provider
                    #default="validationContext"
                    name="nom"
                    rules="required"
                >
                    <b-form-group
                    label="Dénomination"
                    label-for="nom"
                    >
                    <b-form-input
                        id="nom"
                        v-model="maintenanceData.nom"
                        :state="getValidationState(validationContext)"
                        trim
                    />
                    
                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


               <validation-provider
                    #default="validationContext"
                    name="typeaction"
                    rules="required"
                >
                    <b-form-group
                    label="Type d'action"
                    label-for="typeaction"
                    >
                    <v-select
                        id="typeaction"
                        v-model="maintenanceData.typeaction"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeActions"
                        :selectable="option => ! option.value.includes('select_value')"
                        label="text"
                        style="width:300px"
                    />                     

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>


                <validation-provider
                    #default="validationContext"
                    name="description"
                >
                    <b-form-group
                    label="Description"
                    label-for="description"
                    >
                    <b-form-input
                        id="description"
                        v-model="maintenanceData.description"
                        :state="getValidationState(validationContext)"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider> 

               <validation-provider
                    #default="validationContext"
                    name="cadre"
                >
                    <b-form-group
                    label="Cadre"
                    label-for="cadre"
                    >
                    <b-form-input
                        id="cadre"
                        v-model="maintenanceData.cadre"
                        :state="getValidationState(validationContext)"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>  

                <validation-provider
                    #default="validationContext"
                    name="priorite"
                    rules="required"
                >
                    <b-form-group
                    label="Priorité"
                    label-for="priorite"
                    >
                    <v-select
                        id="priorite"
                        v-model="maintenanceData.priorite"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="prioriteOptions"
                        :selectable="option => ! option.value.includes('select_value')"
                        label="label"
                        style="width:300px"
                    />                          

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                    #default="validationContext"
                    name="delai"
                >
                    <b-form-group
                    label="Délai (en jours)"
                    label-for="delai"
                    >
                    <b-form-input
                        id="delai"
                        v-model="maintenanceData.delai"
                        :state="getValidationState(validationContext)"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

               <validation-provider
                    #default="validationContext"
                    name="date"
                >
                    <b-form-group
                    label="Date"
                    label-for="date"
                    >
                    <b-form-datepicker
                        id="date"
                        v-model="maintenanceData.date"
                        :state="getValidationState(validationContext)"
                        locale="fr"
                        v-bind="labels['fr'] || {}"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider> 

               <validation-provider
                    #default="validationContext"
                    name="coutprev"
                >
                    <b-form-group
                    label="Coût estimé"
                    label-for="coutprev"
                    >
                    <b-form-input
                        id="coutprev"
                        v-model="maintenanceData.coutprev"
                        :state="getValidationState(validationContext)"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>   
                
                <validation-provider
                    #default="validationContext"
                    name="pilote"
                >
                    <b-form-group
                    label="Pilote"
                    label-for="pilote"
                    >
                    <b-form-input
                        id="pilote"
                        v-model="maintenanceData.pilote"
                        :state="getValidationState(validationContext)"
                        trim
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider> 

                <validation-provider
                    #default="validationContext"
                    name="ouvrage"
                    rules="required"
                >
                    <b-form-group
                    label="Ouvrage concerné"
                    label-for="ouvrage"
                    >
                    <span class="font-weight-bold text-primary">{{ maintenanceData.ouvragel }}</span>    
                    <ouvragelistmodal :multi="false" class="mt-2" v-on:select-ouvrage="OnSelectOuvrage"/>
                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>    

                <validation-provider
                    #default="validationContext"
                    name="composant"
                    rules="required"
                >
                    <b-form-group
                    label="Composant concerné"
                    label-for="composant"
                    >
                    <span class="font-weight-bold text-primary">{{ maintenanceData.composant }}</span>    
                    <composantlistmodal :multi="false"  class="mt-2" v-on:select-composant="OnSelectComposant"/>
                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider> 

                 <validation-provider
                    #default="validationContext"
                    name="desordre"
                    rules="required"
                >
                    <b-form-group
                    label="Désordre associé"
                    label-for="desordre"
                    >
                    <span class="font-weight-bold text-primary">{{ maintenanceData.desordre }}</span>    
                    <desordrelistmodal :multi="false" class="mt-2" v-on:select-desordre="OnSelectDesordre"/>
                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider> 

<hr/>
          <!-- Form Actions -->
          <div class="d-flex mt-2 mb-2" style="float:right">
            <b-button v-if="!maintenanceData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 mb-2"
              type="submit"
              @click="OnAdd"
            >
              Ajouter
            </b-button>
            <b-button v-if="maintenanceData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 mb-2"
              type="submit"
              @click="OnEdit"
            >
              Mettre à jour
            </b-button>            
          </div>        


 <!--
              <input type="hidden" name="status"  v-model="maintenanceData.status"/>
              <input type="hidden" name="created"   v-model="maintenanceData.created"/> 
              <input type="hidden" name="id"   v-model="maintenanceData.id"/>  
              <input type="hidden" name="author"   v-model="maintenanceData.author"/> 
              <input type="hidden" name="inspection"   v-model="maintenanceData.inspection"/>    -->        

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BImg, BFormFile, BBadge, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import ouvragelistmodal from '@/components/OuvrageListModal.vue'
import composantlistmodal from '@/components/ComposantListModal.vue'
import desordrelistmodal from '@/components/DesordreListModal.vue'
import maintenanceStoreModule from '@/views/apps/maintenance/maintenanceStoreModule'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BImg,
    BFormFile,
    BBadge,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    desordrelistmodal,
    composantlistmodal,
    ouvragelistmodal,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMaintenanceSidebarActive',
    prop: 'inMaintenanceId',
    event: 'update:is-add-new-maintenance-sidebar-active',
  },
  props: {
    isAddNewMaintenanceSidebarActive: {
      type: Boolean,
      required: true,
    },
    inMaintenanceId: {
      type: Number,
      default: -1,
    },   
  },

 methods: {
    loadMaintenance() {
      if ( this.inMaintenanceId != -1) {
          store
          .dispatch('app-maintenance/fetchMaintenance', {
            id: this.inMaintenanceId,
          })
          .then(response => {
              this.maintenanceData = response.data
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching maintenance',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })        
          })
      } else {
        this.maintenanceData = JSON.parse(JSON.stringify(this.blankMaintenanceData))
      }
    },

    OnSelectOuvrage: function (arrayOuvrage) { 
        this.maintenanceData.ouvragel = arrayOuvrage.name
    },

    OnSelectComposant: function (arrayOuvrage) { 
        this.maintenanceData.composant = arrayOuvrage.name
    },    

    OnSelectDesordre: function (arrayOuvrage) { 
        this.maintenanceData.desordre = arrayOuvrage.libelle
    },  

    showMaintenance: function (val) { 

      if (val) {
        this.loadMaintenance()
      } else {
        this.desmaintenanceDatardreData = JSON.parse(JSON.stringify(this.blankMaintenanceData))
      }
    },

 formatDate: function(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
},

   OnAdd: function() {
        this.maintenanceData.typeaction = this.maintenanceData.typeaction.text
        this.maintenanceData.priorite = this.maintenanceData.priorite.label
        if (this.maintenanceData.date != '') {
            this.maintenanceData.statut = 'Planifié'
            this.maintenanceData.date = this.formatDate(this.maintenanceData.date)
        }


       store
      .dispatch('app-maintenance/addMaintenance',this.maintenanceData)
      .then(response => {

          this.maintenanceData = response.data
          this.$emit('addmaintenance')
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error saving maintenance',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })
        
    } ,

    OnEdit: function() {
        if (this.maintenanceData.typeaction.text)
            this.maintenanceData.typeaction = this.maintenanceData.typeaction.text
        if (this.maintenanceData.priorite.label)    
            this.maintenanceData.priorite = this.maintenanceData.priorite.label

       store
      .dispatch('app-maintenance/editMaintenance',this.maintenanceData)
      .then(response => {

          this.maintenanceData = response.data
          this.$emit('addmaintenance')
      })
      //.catch((e) => {console.log(e)})
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error saving maintenance',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })
        
    } ,

 },  

  data() {

      store
      .dispatch('app-maintenance/fetchTypeactions')
      .then(response => {
        this.typeActions = response.data.typeactions        
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching type actions list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })

    //this.desordreData.value = this.inDesordreData
    this.loadMaintenance()
 
    return {
       typeActions:[],
      required,
      alphaNum,
      email,
      prioriteOptions:[
        {value:"Faible",label:"Faible"},
        {value:"Moyenne",label:"Moyenne"},
        {value:"Haute",label:"Haute"},
      ],   

      labels: {
          fr: {
            labelNoDateSelected: 'Pas de date sélectionnée',
            labelHelp: ''
          },  
      },    
    }
  },

  setup(props, { emit }) {

    const USER_APP_STORE_MODULE_NAME = 'app-maintenance'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, maintenanceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankMaintenanceData = {
             id: '',
            nom:'',
            description:'',
            pilote:'',
            delai:'',
            priorite:'',
            coutprev:'',
            coutreal:'',
            duree:'25',
            
            ouvragel:'',
            composant:'',
            typeaction:'',
            statut:'A planifier',
            auteur:'John Doe',
            date:'',
            startdate:'2022/03/12',
            date:'2022/03/12',     
            cadre: '',
            desordre:'',
            documents:''   
    }

    let maintenanceData = ref(JSON.parse(JSON.stringify(blankMaintenanceData)))

//du sens ça ??
    if (props.inMaintenanceId != -1) {
      maintenanceData.value = JSON.parse(JSON.stringify(blankMaintenanceData))
    }

   const resolveUserStatusVariant = status => {
    if (status === 'A planifier') return 'warning'
    if (status === 'Terminé') return 'success'
    if (status === 'Planifié') return 'secondary'
    return 'primary'
  }   
    const resetmaintenanceData = () => {
      maintenanceData.value = JSON.parse(JSON.stringify(blankMaintenanceData))
    }

    const onSubmit = () => {

      emit('update:is-add-new-maintenance-sidebar-active', false)
      emit('addmaintenance', maintenanceData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmaintenanceData)

    return {
      maintenanceData,
      onSubmit,
      blankMaintenanceData,
      refFormObserver,
      getValidationState,
      resetForm,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-desordre-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
