<template>
    <div class="ecommerce-searchbar mt-1" style="height:500px" >

    <maintenance-add-new
      :is-add-new-maintenance-sidebar-active.sync="isAddNewMaintenanceSidebarActive"
      @addmaintenance=' onAddMaintenance'  
        :inMaintenanceId="inMaintenanceId"      
    />

    <maintenance-end
      :is-end-maintenance-sidebar-active.sync="isEndMaintenanceSidebarActive"
      @addmaintenance=' onAddMaintenance'  
        :inMaintenanceId="inMaintenanceId"      
    />    

    <!-- Filters -->
      <maintenance-list-filters
        :asset-options="$userContext.assetList"   
        :status-options="statusOptions"  
        @changeMode="(val) => {currentView = val}"
        @changeAsset="(asset) => handleSearch(asset)"
        @addMaintenance="onNewMaintenance"
        :hideAddNew="mainteneur"
      />      
      <b-row>
        <b-col cols="12" style="height:500px" v-if="currentab === true">
       
       
        <gantt class="left-container" 
          :tasks="maintenanceGanttList" 
          ganttType="maintenance"
          @store="store = $event"
          v-show="currentView === 'gantt'"
          @showDetail="(maintenanceId) => onMaintenanceEdit(Number(maintenanceId))" 
          ></gantt>


     <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="maintenanceList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucune maintenance trouvée"
        :sort-desc.sync="isSortDirDesc"
        v-show="currentView === 'list'"
      >
        <template #empty="scope">
          <h4>Aucune maintenance trouvée</h4>
        </template>
        <template #emptyfiltered="scope">
          <h4>Aucune maintenance trouvée</h4>
        </template>
        <!-- Column: User -->
        <template #cell(nom)="data">
          <span class="align-text-top text-capitalize" v-if="mainteneur === 1">     
            {{ data.item.nom }}
          </span>
            <b-link  v-if="mainteneur !== 1"
            @click="onMaintenanceEdit(data.item.id)"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.nom }}
            </b-link>
        </template>

        <!-- Column: Role -->
        <template #cell(ouvrage)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.ouvragelst }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(date)="data">
          <span class="align-text-top text-capitalize">     
            {{ data.item.date }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(desordre)="data">
          <span class="align-text-top text-capitalize" v-if="mainteneur !== 1">     
            {{ data.item.desordre }}
          </span>
            <b-link
            @click="onMaintenanceEndEdit(data.item.id)"
              class="font-weight-bold d-block text-nowrap"
               v-if="mainteneur === 1"
            >
              Déclarer fin d'opération
            </b-link>       
        </template>        

        <!-- Column: Status -->
        <template #cell(statut)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.statut)}`"
            class="text-capitalize"
          >
            {{ data.item.statut }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(priorite)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserRoleVariant(data.item.priorite)}`"
            class="text-capitalize"
          >
            {{ data.item.priorite }}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Maintenances {{ dataMeta.from }} à {{ dataMeta.to }} sur {{ totalEntries }} entrées</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMaintenances"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-show="currentView === 'list'"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>




        </b-col>
      </b-row>  
  </div>

</template>

<script>

import Gantt from '../../../components/Gantt.vue';
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import maintenanceStoreModule from '@/views/apps/maintenance/maintenanceStoreModule'
import MaintenanceListFilters from '@/views/apps/maintenance/MaintenanceListFilters.vue'
import Assettypes from '@/@fake-db/data/apps/assettype.js'
import useMaintenanceList from '@/views/apps/maintenance/useMaintenanceList'
import MaintenanceAddNew from '@/views/apps/maintenance/MaintenanceAddNew.vue'
import MaintenanceEnd from '@/views/apps/maintenance/MaintenanceEnd.vue'

import {
   BRow, BCol,BTable,BLink, BBadge,BPagination,
} from 'bootstrap-vue'
 
export default {
  name: 'app',
  props: { 
    mainteneur: {
      type: Number,
    },                    
  },
  components: {Gantt,maintenanceStoreModule,MaintenanceListFilters,BRow,BCol,BTable,BLink, BBadge,BPagination,MaintenanceAddNew,MaintenanceEnd,},
data () {
    this.filters = {assets:[],priorite:[],type:[],queries:[], status:[]}
    this.getMaintenanceList()
      
    return {
      currentView: 'list',
      maintenanceGanttList: {
        data: [],
        links: [],
      },
      inMaintenanceId:-1,
      filters: {assets:[],priorite:[],type:[],queries:[], status:[]} ,
      maintenanceList:[],
      globalOptions: {typeOptions:[], desordreOptions:[],prioriteOptions:[]},
      typeOptions:[],
      desordreOptions:[],
      prioriteOptions:[],
      currentab : false,
      totalEntries: 0
    }
  },


   methods: {
    addMaintenance: function (maintenance) {
      if (maintenance.statut != "A planifier") {
        let task = {id:maintenance.id, text:(maintenance.ouvragel+'-'+maintenance.nom), start_date:maintenance.startdate,duration:maintenance.duree, status:maintenance.statut, type:'maintenance'}
        this.maintenanceGanttList = {data:[...this.maintenanceGanttList.data,task]}
      }
      
      this.maintenanceList.push(maintenance)
    },
   
    onNewMaintenance: function() {
       this.inMaintenanceData = {id:''}     
       this.isAddNewMaintenanceSidebarActive = true      
      //this.$router.push({ name: 'apps-maintenance-add' })
    },

    onMaintenanceEdit: function (maintenanceId) { 
       this.inMaintenanceId = maintenanceId     
       this.isAddNewMaintenanceSidebarActive = true

    },       


    onMaintenanceEndEdit: function (maintenanceId) { 
       this.inMaintenanceId = maintenanceId     
       this.isEndMaintenanceSidebarActive = true

    },       

    onAddMaintenance: function () { 
        this.isAddNewMaintenanceSidebarActive = false
        /*store
            .dispatch('app-maintenance/addMaintenance', 
                maintenance,
            )
            .then(response => {

                maintenance = response.data.desomaintenancerdre

                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Maitenance créé avec succès',
                    icon: 'EditIcon',
                    variant: 'success',
                    },
                })              
            })
            .catch(() => {
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Une erreur est survenue lors de la création',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
                })        
            })*/
            this.getMaintenanceList()
  }, 



  handleSearch: function(val) {

      this.filters = {assets:[],priorite:[],type:[],queries:[], status:[]} 
  
     val.forEach(
       a =>{
        if (typeof(a.id) !== "undefined") {
          if (a.type === 'asset') {
              this.filters.assets.push(a.name)
          } else if (a.type === 'priorite') {
              this.filters.priorite.push(a.id)
          } else if(a.type === 'type') {
              this.filters.type.push(a.id)
          } else if(a.type === 'status') {
              this.filters.status.push(a.id)
          } 
        } else {
          this.filters.queries.push(a.name)
        }
       }
     )

       this.getMaintenanceList()

    }, 

    sortOuvragelist: function(a, b) {
      if (a.id > b.id) 
        return -1
      if (a.id < b.id)
        return 1
      return 0
    },

  getMaintenanceList: function() {

      this.maintenanceList = []
      this.maintenanceGanttList = {
        data: [],
        links: [],
      },

      store
      .dispatch('app-maintenance/fetchMaintenances', {
        q: this.filters,
      })
      .then(response => {

        const { maintenances, total } = response.data
        this.totalEntries = total
        maintenances.forEach(maintenance => this.addMaintenance(maintenance));
        this.currentab = true
        this.maintenanceList.sort(this.sortOuvragelist)
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching inspections list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })        
      })
      .catch((e) => console.log(e))
  }



  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-maintenance'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, maintenanceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'A planifier', value: 'A planifier' },
      { label: 'Planifié', value: 'Planifié' },
      { label: 'En cours', value: 'En cours' },
      { label: 'Terminé', value: 'Terminé' },
    ]

    const {
      fetchMaintenances,
      fetchTypeactions,
      tableColumns,
      perPage,
      currentPage,
      totalMaintenances,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      statusFilter,
      typeFilter,
      prioriteFilter,
    } = useMaintenanceList()

     const isAddNewMaintenanceSidebarActive = ref(false)
     const isEndMaintenanceSidebarActive = ref(false)

    return { 

      fetchMaintenances,
      fetchTypeactions,
      tableColumns,
 
      perPage,
      currentPage,
      totalMaintenances,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,


      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      statusOptions,

      // Extra Filters
      statusFilter,
      typeFilter,
      prioriteFilter,            
      Assettypes,
      isAddNewMaintenanceSidebarActive,
      isEndMaintenanceSidebarActive
    }

  },

  mounted() {
    if (this.mainteneur === 1) {
      this.tableColumns = [
      { key: 'priorite',label:"Priorité", sortable: true },
      { key: 'ouvragel',label:"Ouvrage", sortable: true },
      { key: 'nom',label:"Dénomination", sortable: true },
      { key: 'typeaction',label:"Type", sortable: true },
      { key: 'desordre',label:"", sortable: true },
      ]      
    }
  },  
}
</script>

<style>
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .container {
    height: 100%;
    width: 100%;
  }
  .left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .gantt_task_cell {
    width: 35px;
  }
</style>