<template>
  <b-card no-body class="mb-0">
    <b-card-body class="mb-0 myclass">
      <b-row>       
        <b-col
          cols="12"
          md="9"
          class="mb-md-0 mb-2"
        >
          <label>Recherche</label>
          <search-box 
            :asset-list="localAssetList"
            :selected1="selectedAssets"
            @changeAsset="(asset) => $emit('changeAsset', asset)"
            />
        </b-col>        
        <b-col           cols="12"
          md="3"
          class="mb-md-0 mb-2 buttonswitch">
          <button class="mybutton" title="Voir la liste" v-on:click="showMode('list')"><img height="30px" width="30px" :src="require('@/assets/images/ficheiqoa/list2.png')" /></button>
          <button class="mybutton" title="Voir le planning" v-on:click="showMode('gantt')"><img height="30px" width="30px" :src="require('@/assets/images/ficheiqoa/map.png')" /></button>
          <b-button
            variant="primary"
            @click="$emit('addMaintenance')"
            class="ml-2"
            v-if="hideAddNew !== 1"
          >
            <span  class="text-nowrap">Ajouter une maintenance</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,BInputGroup,BInputGroupAppend,BFormInput, BFormRadioGroup, BIcon, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SearchBox from './SearchBox'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormRadioGroup,
    SearchBox,
    BIcon
  },


  mounted() {
    this.buildAssetlist()
  },

  data() {
    
    return {
      viewMode: 'list',
      localAssetList:[],
      selectedAssets:{}
    }
  },

  methods: {

    buildAssetlist: function() {
      
      
      this.localAssetList = []
       
      this.assetOptions.forEach(a => this.localAssetList.push({name:a.name, id:a.id, type:'asset'}))  
      
      this.statusOptions.forEach(w => this.localAssetList.push({name:w.label, id:w.label, type:'status'})) 

      var prioriteOptions = [
            { label: 'Haute', value: 'Haute' },
            { label: 'Moyenne', value: 'Moyenne' },
            { label: 'Faible', value: 'Faible' },
          ]
      
      prioriteOptions.forEach(a => this.localAssetList.push({name:'Priorité '+a.label, id:a.value, type:'priorite'}))

      var   typeAction =[
    {value:"Réparation",text:"Réparation",label:"Réparation"},
    {value:"Renforcement",text:"Renforcement",label:"Renforcement"},
    {value:"Surveillance",text:"Surveillance",label:"Surveillance"},
    {value:"Diagnostic",text:"Diagnostic",label:"Diagnostic"},
      ]
      
      typeAction.forEach(a => this.localAssetList.push({name:a.label, id:a.value, type:'type'}))

    },

    showMode: function(mode) {
      this.viewMode = mode
      this.$emit('changeMode', this.viewMode)
    }
  },

  props: { 
    assetOptions: {
      type: Array,
      required: true,
    },    
    statusOptions: {
      type: Array,
      required: true,
    },      
    hideAddNew: {
      type: Number, 
    }              
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.myclass {
  padding: 1rem;
}

.buttonswitch {
display: flex;
align-items: center;
justify-content: right;
}

.mybutton {
  background-color: white; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
</style>
