import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDesordres(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/desordre/desordres', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDesordre(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/desordre/desordres/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDesordre(ctx, desordreData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/desordre/desordres', { desordre: desordreData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editDesordre(ctx, desordreData) {
      return new Promise((resolve, reject) => {
        axios
          .patch('/apps/desordre/desordres', { desordre: desordreData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },    
  },
}
